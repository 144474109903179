import { genNumFromSeed } from '../../../util';
import Planet from './Planet';

export default class System {
  static IS_PLANET_BIT = 10;
  static PLANET_FOR_EVERY = 20;

  isPlanet = false;
  planet: Planet;
  public seed: string;
  constructor(seed: string) {
    this.seed = seed;
    this.isPlanet =
      genNumFromSeed(seed, System.PLANET_FOR_EVERY, System.IS_PLANET_BIT) === 0;
  }

  public getPlanet() {
    if (!this.isPlanet) return null;
    if (!this.planet) this.planet = new Planet(this.seed);

    return this.planet;
  }
}
