import { genNumFromSeed } from '../../../../util';
import Biome from './Biome';
import { Biomes, BiomeTypes } from './Biome/Biome.model';
import { BiomeDensities, BiomeDensity } from './Planet.model';
import { generateBiomesFromSeed } from './Planet.util';

export default class Planet {
  static MAX_SIZE = 10;
  static MIN_SIZE = 1;

  public size: number;
  public baseBiome: BiomeTypes;
  public density: BiomeDensity;
  private biomesCache: BiomeTypes[][];
  public seed: string;
  constructor(seed: string) {
    this.seed = seed;
    this.size =
      genNumFromSeed(seed, Planet.MAX_SIZE - Planet.MIN_SIZE) + Planet.MIN_SIZE;
    this.baseBiome = Biomes[genNumFromSeed(this.seed, Biomes.length, 1)];
    this.density =
      BiomeDensities[genNumFromSeed(this.seed, BiomeDensities.length, 2)];
  }

  get biomes() {
    if (!this.biomesCache) {
      this.biomesCache = generateBiomesFromSeed(
        this.seed,
        this.size,
        this.baseBiome,
        this.density,
      );
    }

    return this.biomesCache;
  }

  public getBiome(x: number, y: number) {
    return new Biome(this.biomes[y][x], x, y, this.seed);
  }

  public debug() {
    console.log(
      `${this.density} ${this.baseBiome} Planet
      Size: ${this.size}
      Seed: ${this.seed}
Biomes: \n${this.biomes.map((row) => row.map((biome) => biome[0])).join('\n')}
      `,
    );
  }
}
