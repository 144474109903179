export const genNumFromSeed = (
  seed: string,
  maxNum: number,
  offset = 0,
): number => {
  let hash = 0;
  for (let i = 0; i < seed.length; i++) {
    const charCode = seed.charCodeAt(i);
    hash += charCode;
  }
  return (hash + offset) % maxNum;
};
