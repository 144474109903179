import { BiomeTypes } from '../Biome.model';
import { v5 as uuidv5 } from 'uuid';
import { DASH_REGEX } from '../../../../../../constants';

//TODO select TileType
export default class Tile {
  public seed: string;
  public biomeType: BiomeTypes;
  constructor(biomeType: BiomeTypes, x: number, y: number, seed: string) {
    this.biomeType = biomeType;
    this.seed = uuidv5(y.toString(), uuidv5(x.toString(), seed)).replace(
      DASH_REGEX,
      '',
    );
  }
}
