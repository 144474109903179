import { genNumFromSeed } from '../../../../util';
import { Biomes, BiomeTypes } from './Biome/Biome.model';
import { DensityToSpreadRatio } from './Planet.constants';
import { BiomeDensity } from './Planet.model';

const EAST = [0, 1];
const WEST = [0, -1];
const SOUTH = [1, 0];
const NORTH = [-1, 0];

const directions = [EAST, WEST, SOUTH, NORTH];

export const propagateFromAnchors = (
  size: number,
  baseBiome: BiomeTypes,
  anchors: [[number, number], BiomeTypes, number][],
) => {
  const tiles: BiomeTypes[][] = new Array(size)
    .fill(null)
    .map(() => new Array(size).fill(null));
  while (anchors.length) {
    const [[y, x], type, distanceLeft] = anchors.shift();
    if (!tiles[y][x]) {
      tiles[y][x] = type;
    }

    if (distanceLeft > 0) {
      directions.forEach(([yDir, xDir]) => {
        let [yDest, xDest] = [y + yDir, x + xDir];
        if (xDest < 0) {
          xDest = size - 1;
        } else if (xDest === size) {
          xDest = 0;
        }
        if (yDest < 0) {
          yDest = size - 1;
        } else if (yDest === size) {
          yDest = 0;
        }
        if (tiles[yDest][xDest] === null) {
          anchors.push([[yDest, xDest], type, distanceLeft - 1]);
        }
      });
    }
  }

  tiles.forEach((row) => {
    row.forEach((biome, x) => {
      if (biome === null) {
        row[x] = baseBiome;
      }
    });
  });

  return tiles;
};

export const generateAnchors = (
  seed: string,
  size: number,
  density: BiomeDensity,
) => {
  const anchors: [[number, number], BiomeTypes, number][] = [];
  for (let x = 0; x < size; x++) {
    for (let y = 0; y < size; y++) {
      const num = genNumFromSeed(seed, 4, x + y);
      if (num === 0) {
        anchors.push([
          [x, y],
          Biomes[(x * y) % Biomes.length],
          DensityToSpreadRatio[density],
        ]);
      }
    }
  }
  return anchors;
};

export const generateBiomesFromSeed = (
  seed: string,
  size: number,
  baseBiome: BiomeTypes,
  density: BiomeDensity,
) => {
  return propagateFromAnchors(
    size,
    baseBiome,
    generateAnchors(seed, size, density),
  );
};
