import System from '../../Game/Galaxy/System';
import { v5 as uuidv5 } from 'uuid';
import { DASH_REGEX } from '../../constants';

export default class Galaxy {
  private hashingNameSpace: string;
  private hashCache: { [x: number]: { [y: number]: string } } = {};
  public size: number;
  constructor(size = 100) {
    this.size = size;
    this.hashingNameSpace = uuidv5(size.toString(), uuidv5.URL);
  }

  private hash(x: number, y: number): string {
    x = x % this.size;
    y = y % this.size;
    if (!this.hashCache[x]) this.hashCache[x] = {};

    if (!this.hashCache[x][y]) {
      this.hashCache[x][y] = uuidv5(
        y.toString(),
        uuidv5(x.toString(), this.hashingNameSpace),
      ).replace(DASH_REGEX, '');
    }

    return this.hashCache[x][y];
  }

  public getSystem(x: number, y: number): System {
    const seed = this.hash(x, y);
    return new System(seed);
  }
}
