export enum BiomeDensity {
  Sparse = 'Sparse',
  Average = 'Average',
  Dense = 'Dense',
}

export const BiomeDensities = [
  BiomeDensity.Sparse,
  BiomeDensity.Average,
  BiomeDensity.Dense,
];
