export enum BiomeTypes {
  Snow = 'Snow',
  Forest = 'Forest',
  Mountains = 'Mountains',
  Desert = 'Desert',
  Grassland = 'Grassland',
  Barren = 'Barren',
  Ocean = 'Ocean',
}

export const Biomes = [
  BiomeTypes.Snow,
  BiomeTypes.Forest,
  BiomeTypes.Mountains,
  BiomeTypes.Desert,
  BiomeTypes.Grassland,
  BiomeTypes.Barren,
  BiomeTypes.Ocean,
];
