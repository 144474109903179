.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  flex: 0;
  display: flex;
  padding: 10px;
}

.input {
  margin-right: 10px;
}

.submit {
  font-family: 'Courier New', Courier, monospace;
}

.galaxy {
  flex: 1;
}

.barren {
  background-color: gray;
}

.desert {
  background-color: gold;
}

.forest {
  background-color: forestgreen;
}

.mountains {
  background-color: red;
}

.ocean {
  background-color: blue;
}

.snow {
  background-color: snow;
}

.grassland {
  background-color: springgreen;
}

.space {
  background-color: black;
}

.systemContainer {
}
