import { BiomeTypes } from './Biome.model';
import { v5 as uuidv5 } from 'uuid';
import { DASH_REGEX } from '../../../../../constants';
import Tile from './Tile';
/**
 * Biomes are 4 by 4 grids, each cell representing 2 characters of the seed
 * For each cell, those 2 characters determine permanent/random details
 */
export default class Biome {
  public biomeType: BiomeTypes;
  public seed: string;
  constructor(biomeType: BiomeTypes, x: number, y: number, seed: string) {
    this.biomeType = biomeType;
    this.seed = uuidv5(y.toString(), uuidv5(x.toString(), seed)).replace(
      DASH_REGEX,
      '',
    );
  }

  public getTile(x: number, y: number) {
    return new Tile(this.biomeType, x, y, this.seed);
  }
}
