import styles from './App.module.scss';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import Galaxy from '../../Game/Galaxy';
import { BiomeTypes } from '../../Game/Galaxy/System/Planet/Biome/Biome.model';
import System from '../../Game/Galaxy/System';
import SystemComponent from './System';

interface AppProps {
  className?: string;
}

const classNames = {
  [BiomeTypes.Barren]: styles.barren,
  [BiomeTypes.Desert]: styles.desert,
  [BiomeTypes.Forest]: styles.forest,
  [BiomeTypes.Mountains]: styles.mountains,
  [BiomeTypes.Ocean]: styles.ocean,
  [BiomeTypes.Snow]: styles.snow,
  [BiomeTypes.Grassland]: styles.grassland,
};

const App = ({ className }: AppProps) => {
  const [height, setHeight] = useState(100);
  const [systems, setSystems] = useState<BiomeTypes[][]>(null);
  const [selectedSystem, setSelectedSystem] = useState<System | null>(null);

  const handleGenerate: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const galaxy = new Galaxy(height);
    setSelectedSystem(null);
    setSystems(
      new Array(height).fill(null).map((_, x) =>
        new Array(height).fill(null).map((_, y) => {
          return galaxy.getSystem(x, y).getPlanet()?.baseBiome;
        }),
      ),
    );
  };

  const handleClickSystem = (x: number, y: number) => {
    const galaxy = new Galaxy(height);
    setSelectedSystem(galaxy.getSystem(x, y));
  };

  const systemElements = useMemo(() => {
    if (systems) {
      return systems.reduce<JSX.Element[]>((acc, row, rowIdx) => {
        row.forEach((system, colIdx) => {
          if (system) {
            const className = classNames[system];
            acc.push(
              <div
                key={`${rowIdx}-${colIdx}`}
                className={className}
                onClick={() => handleClickSystem(rowIdx, colIdx)}
              />,
            );
          } else {
            acc.push(
              <div key={`${rowIdx}-${colIdx}`} className={styles.space} />,
            );
          }
        });

        return acc;
      }, []);
    }
    return <div>Must generate Galaxy</div>;
  }, [systems]);

  return (
    <div
      className={cn(className, styles.container)}
      data-testid={'app-container'}
    >
      <form className={styles.form} onSubmit={handleGenerate}>
        <input
          className={styles.input}
          type={'number'}
          value={height}
          onChange={(e) => setHeight(Number(e.target.value))}
        />
        <button className={styles.submit}>Generate</button>
      </form>
      {selectedSystem ? (
        <SystemComponent
          className={styles.systemContainer}
          system={selectedSystem}
        />
      ) : (
        <div
          className={styles.galaxy}
          style={{
            display: 'grid',
            gridTemplateRows: `repeat(${systems?.length}, 5px)`,
            gridTemplateColumns: `repeat(${systems?.length}, 5px)`,
          }}
        >
          {systemElements}
        </div>
      )}
    </div>
  );
};

export default App;
