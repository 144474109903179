import styles from './System.module.scss';
import cn from 'classnames';
import SystemClass from '../../../Game/Galaxy/System';
import { useMemo } from 'react';
import { BiomeTypes } from '../../../Game/Galaxy/System/Planet/Biome/Biome.model';

interface SystemProps {
  className?: string;
  system: SystemClass;
}

const classNames = {
  [BiomeTypes.Barren]: styles.barren,
  [BiomeTypes.Desert]: styles.desert,
  [BiomeTypes.Forest]: styles.forest,
  [BiomeTypes.Mountains]: styles.mountains,
  [BiomeTypes.Ocean]: styles.ocean,
  [BiomeTypes.Snow]: styles.snow,
  [BiomeTypes.Grassland]: styles.grassland,
};

const System = ({ className, system }: SystemProps) => {
  const biomes = useMemo(() => {
    const systemBiomes = system.getPlanet().biomes;
    return systemBiomes?.reduce<JSX.Element[]>((acc, biomesRow, rowIdx) => {
      biomesRow.forEach((biome, colIdx) => {
        const className = classNames[biome];

        acc.push(<div key={`${rowIdx}-${colIdx}`} className={className} />);
      });
      return acc;
    }, []);
  }, [system]);
  return (
    <div
      className={cn(className, styles.container)}
      style={{
        display: 'grid',
        gridTemplateRows: `repeat(${system.getPlanet().size}, 30px)`,
        gridTemplateColumns: `repeat(${system.getPlanet().size}, 30px)`,
      }}
    >
      {biomes}
    </div>
  );
};

export default System;
